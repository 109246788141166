@use './mixin' as r;
@use '../abstruct/variable' as v;

@use '../../node_modules/sanitize.css/sanitize.css';
@use '../../node_modules/sanitize.css/forms.css';

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

li {
  list-style-type: none;
}

th {
  font-weight: inherit;
}

address {
  font-style: inherit;
}

fieldset {
  min-width: 0;
  padding: 0;
  border: 0;
}

a {
  color: currentColor;
  text-decoration: none;
}

a[href^='tel:'] {
  @include r.mq(mdover) {
    pointer-events: none;
  }
}

@media screen and (max-width: 767px) {
}

button,
input,
select,
textarea {
  padding: 0;
  border: 0;
}

button:not(:disabled):not([aria-disabled='true']) {
  cursor: pointer;
}

html {
  font-size: 62.5%;
}

body {
  // min-width: 105.6rem;
  // font-family: 'Noto Sans JP', sans-serif;
  // font-family: -apple-system, 'Yu Gothic', '游ゴシック', YuGothic,
  //   '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ', 'Yu Gothic Medium',
  //   'Yu Gothic', Verdana, Meiryo, sans-serif;
  // font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', sans-serif;
  font-family: 'Helvetica', 'Arial', 'Hiragino Kaku Gothic ProN',
    'Hiragino Sans', Yu Gothic, '游ゴシック', 'メイリオ', Meiryo, sans-serif;
  font-weight: 400;
  // overflow: hidden;

  // @include r.mq(mdless) {
  //   min-width: 100%;
  // }

  @include r.mq(mdover) {
    font-size: 1.5rem;
  }
}

// Safari用のハックは、Chromeに適用されないようにする
@supports (-webkit-touch-callout: none) {
  body {
    // Safari用のハック
    height: -webkit-fill-available;
  }
}

[v-cloak] {
  visibility: hidden;
}

.l-wrapper {
  position: relative;
  overflow: hidden;

  img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    @include r.mq(mdover) {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

.is-hover {
  @include r.mq(mdover) {
    display: inline-block;
    opacity: 1;
    transition: opacity 0.4s ease-out;
  }

  &:hover {
    @include r.mq(mdover) {
      opacity: 0.6;
    }
  }
}

.pc {
  display: block;

  @include r.mq(mdless) {
    display: none;
  }
}

.sp {
  display: none;

  @include r.mq(mdless) {
    display: block;
  }
}
