$breakpoints: (
  'xs': (
    min-width: 0,
  ),
  'sm': (
    min-width: 576px,
  ),
  'mdover': (
    min-width: 768px,
  ),
  'mdless': (
    max-width: 767px,
  ),
  'xl': (
    min-width: 1200px,
  ),
  'xxl': (
    min-width: 1400px,
  ),
) !default;

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @error "指定されたブレイクポイントはありません -> #{map-keys($breakpoints)}";
  }
}
@mixin aspect($w, $h) {
  position: relative;

  &::before {
    float: left;
    padding-top: ($h / $w) * 100%;
    content: '';
  }

  &::after {
    display: table;
    clear: both;
    content: '';
  }

  & > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
